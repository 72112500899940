import { Body } from '@sumup/circuit-ui';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState, type FC } from 'react';

import { getDigitalProductCTALink } from 'productSelection/services/product';
import type { ProductCardContent } from 'productSelection/types/content';
import type { DigitalProduct, Product } from 'productSelection/types/products';
import { MERCHANT_ID } from 'shared/infra/cookies';
import { getDiscountDisplayValue } from 'shared/services/DiscountService';
import { getResizedUrl } from 'shared/services/imageUrl';
import { getUserIdFromBrowser } from 'shared/services/optimizely/browser-user';
import {
  dispatchMoreInfoClick,
  dispatchSkipShopEvent,
} from 'shared/services/tracker/events';
import { ACTIONS, ENTITIES, TRIGGERS } from 'shared/services/walker/constants';
import { getTaggingData } from 'shared/services/walker/tagging';
import { useTypedSelector } from 'shared/store';
import type { OrderLineItem } from 'shared/store/order/types';
import type { LineItemType } from 'shared/types/product';
import { getChannelLink } from 'shared/utils/channel-link';

import { StrikeThroughPrice } from '../StrikeThroughPrice';
import { TaxMessage } from '../TaxMessage';

import { ImageWrapper } from './ImageWrapper';
import { PriceComponent } from './Price';
import {
  CTAsWrapper,
  ImageRatio,
  InstallmentsPriceInfo,
  MoreInfoCTA,
  NameLink,
  PriceContainer,
  PriceWrapper,
  ProductContent,
  ProductHandles,
  RelativeDiv,
  SelectButton,
  StrikeThroughInstallmentsPrice,
  StyledBadge,
  StyledInstallmentPrice,
  StyledOutOfStockBadge,
  StyledQuantitySelector,
} from './ProductCard.styles';
import { ProductName } from './ProductName';
import { mapNonCrTrackingId } from './services/tracking';
import { ShortBenefits } from './ShortBenefits';

export interface Props extends Partial<Product>, Partial<DigitalProduct> {
  // when true, disabled the CTA and changes the button label
  isSelected?: boolean;
  // when true, highlights the product card with a border
  isHighlighted?: boolean;
  isLoading?: boolean;
  taxMessage?: string;
  content: ProductCardContent;
  onSelect?: (
    code: string,
    lineItemType: LineItemType,
    quantity: number,
  ) => void;
  discountRate?: number;
  numberOfInstallments?: number;
  installmentsFeeInfo?: string;
  fullPriceInfo?: string;
  formattedInstallmentAmount?: string;
  formattedInstallmentOriginalAmount?: string;
  isDigitalProduct?: boolean;
  /**
   * Product card with installment information has longer height.
   * To balance price position, we vertically centralize price
   * information when there is at least one product has installments.
   */
  isInstallmentsLayout?: boolean;
  shortBenefits?: string;
  productsCount: number;
  isDiscountPriceEnabled?: boolean;
  stockQuantity?: number | null;
  addToCartText?: string;
  orderProduct?: OrderLineItem;
  purchaseLimit?: number;
}

const DEFAULT_IMAGE_SIZE = 304;

export const ProductCard: FC<Props> = ({
  code,
  lineItemType,
  trackingId,
  name,
  formattedUnitAmount,
  formattedTotalAmount,
  discountRate,
  productContent,
  isSelected,
  isHighlighted,
  onSelect,
  content,
  isLoading,
  slug,
  linkToDigitalProduct,
  numberOfInstallments,
  installmentsFeeInfo,
  fullPriceInfo,
  formattedInstallmentAmount,
  formattedInstallmentOriginalAmount,
  selectProductLabel,
  isDigitalProduct,
  isInstallmentsLayout = false,
  productsCount,
  isDiscountPriceEnabled = false,
  stockQuantity = null,
  shortBenefits,
  addToCartText,
  taxMessage,
  amountFloat,
  isFree,
  orderProduct,
  purchaseLimit,
}) => {
  const linkRef = useRef<HTMLAnchorElement>();
  const { query } = useRouter();
  const { orderDetails } = useTypedSelector((state) => state.order);
  const { imageSrc, imageAltText } = productContent;
  const [quantity, setQuantity] = useState(1);
  const userId = getUserIdFromBrowser();

  const imageSize = Math.floor(3 * DEFAULT_IMAGE_SIZE);
  const {
    discountBadgeText,
    freeBadgeText,
    loadingText,
    moreInfoText,
    outOfStockText,
  } = content;
  const isOutOfStock = stockQuantity !== null && stockQuantity <= 0;
  const purchasableQuantity = Math.max(
    purchaseLimit - (orderProduct?.quantity || 0),
    0,
  );
  const discountDisplayValue = getDiscountDisplayValue(discountRate * 100);
  const hasDiscount = discountRate > 0 && !isDigitalProduct;
  const showDiscountBadge =
    isDiscountPriceEnabled && hasDiscount && !isOutOfStock;
  const hasInstallments = numberOfInstallments > 1;
  const ctaLabel = selectProductLabel || addToCartText;
  const isSelectionDisabled =
    isSelected ||
    isLoading ||
    (!formattedTotalAmount && !isDigitalProduct) ||
    isOutOfStock;

  const handleClickProductName = (): void => {
    void dispatchMoreInfoClick({
      from: 'image',
      userId,
      productName: name,
      merchantCode: localStorage.getItem(MERCHANT_ID),
      orderId: orderDetails.id,
    });
  };

  const handleClickProductCard = (e: React.SyntheticEvent): void => {
    if (e.target !== linkRef.current) {
      linkRef.current?.click();
    }
  };

  const handleSelectProduct = (): void => {
    if (isDigitalProduct) {
      void dispatchSkipShopEvent({
        label: name,
        userId,
        merchantCode: localStorage.getItem(MERCHANT_ID),
        orderId: orderDetails.id,
        trackingId,
        context: 'product_card',
      });
    }
    onSelect?.(code, lineItemType, quantity);
  };

  const linkToProduct = `${getChannelLink(
    '/product-selection/[productName]',
  )}?${new URLSearchParams({
    ...query,
    productName: slug,
  }).toString()}`;

  const linkToDigitalProductWithParams = isDigitalProduct
    ? getDigitalProductCTALink(linkToDigitalProduct, query)
    : null;

  const taggingData = useMemo(() => {
    if (!isDigitalProduct) {
      return {};
    }
    return getTaggingData({
      entity: ENTITIES.BUTTON,
      action: {
        trigger: TRIGGERS.CLICK,
        action: ACTIONS.CLICKED,
      },
      properties: {
        details: {
          business_flow: 'product selection',
          button_description: 'explore_product',
          option: mapNonCrTrackingId(trackingId),
        },
      },
    });
  }, [isDigitalProduct, trackingId]);

  return (
    <>
      <RelativeDiv
        onClick={handleClickProductCard}
        {...getTaggingData({
          properties: {
            entity: ENTITIES.PRODUCT,
            details: {
              name: trackingId,
            },
          },
        })}
      >
        <ImageWrapper isHighlighted={isHighlighted}>
          <ImageRatio>
            <Image
              src={getResizedUrl(imageSrc, imageSize, imageSize)}
              alt={imageAltText}
              priority
              fill
              sizes="(max-width: 479px) 176px, (max-width: 767px) 272px, (max-width: 959px) 226px, (max-width: 1279px) 266px, 367px"
            />
          </ImageRatio>
          {showDiscountBadge && (
            <StyledBadge
              variant="promo"
              data-testid={`PRODUCT_CARD.${slug}.DISCOUNT_BADGE`}
            >
              {discountDisplayValue}
              {discountBadgeText}
            </StyledBadge>
          )}
          {isOutOfStock && (
            <StyledOutOfStockBadge
              isOutOfStock={isOutOfStock}
              data-testid="out-of-stock-badge"
            >
              {outOfStockText}
            </StyledOutOfStockBadge>
          )}
        </ImageWrapper>

        <ProductContent isHighlighted={isHighlighted}>
          <ProductName productsCount={productsCount}>
            <NameLink
              href={linkToProduct}
              ref={linkRef}
              aria-describedby={slug}
              onClick={handleClickProductName}
            >
              {name}
            </NameLink>
          </ProductName>

          {shortBenefits && <ShortBenefits shortBenefits={shortBenefits} />}

          <PriceWrapper
            {...getTaggingData({
              properties: {
                entity: ENTITIES.PRODUCT,
                details: {
                  price: amountFloat,
                },
              },
            })}
          >
            {!isDigitalProduct && (
              <>
                {hasInstallments ? (
                  <div css={{ marginTop: 'var(--cui-spacings-byte)' }}>
                    {isDiscountPriceEnabled && hasDiscount && (
                      <StrikeThroughInstallmentsPrice variant="subtle">
                        {`${numberOfInstallments}x ${formattedInstallmentOriginalAmount}`}
                      </StrikeThroughInstallmentsPrice>
                    )}
                    <PriceContainer isPrimaryColor>
                      <InstallmentsPriceInfo
                        size="two"
                        css={{ marginRight: 'var(--cui-spacings-bit)' }}
                      >
                        {numberOfInstallments}x
                      </InstallmentsPriceInfo>
                      <StyledInstallmentPrice size="one" as="h2">
                        {formattedInstallmentAmount}
                      </StyledInstallmentPrice>
                      <InstallmentsPriceInfo
                        size="two"
                        css={{ marginLeft: 'var(--cui-spacings-bit)' }}
                      >
                        {installmentsFeeInfo}
                      </InstallmentsPriceInfo>
                    </PriceContainer>
                    <Body
                      size="two"
                      variant="subtle"
                      css={{
                        marginTop: 'var(--cui-spacings-bit)',
                        marginBottom: 'var(--cui-spacings-byte)',
                      }}
                    >
                      {`${fullPriceInfo} ${formattedTotalAmount}`}
                    </Body>
                  </div>
                ) : (
                  <>
                    <PriceContainer>
                      <PriceComponent>{formattedTotalAmount}</PriceComponent>
                      {isDiscountPriceEnabled && hasDiscount && (
                        <StrikeThroughPrice>
                          {formattedUnitAmount}
                        </StrikeThroughPrice>
                      )}
                    </PriceContainer>
                    <TaxMessage taxMessage={taxMessage} light />
                  </>
                )}
              </>
            )}
            {isDigitalProduct && isFree && (
              <PriceContainer isVerticallyCenter={isInstallmentsLayout}>
                <PriceComponent isFree data-testid="freeBadge">
                  {freeBadgeText}
                </PriceComponent>
              </PriceContainer>
            )}
          </PriceWrapper>
        </ProductContent>
      </RelativeDiv>

      <CTAsWrapper>
        <ProductHandles>
          {!isDigitalProduct && (
            <StyledQuantitySelector
              currentlyAdded={orderProduct?.quantity || 0}
              onChange={setQuantity}
              quantityLimit={purchasableQuantity}
            />
          )}
          <SelectButton
            href={linkToDigitalProductWithParams}
            loadingLabel={loadingText}
            isLoading={isLoading}
            data-testid={`product_card.${trackingId}.CALL_TO_ACTION`}
            disabled={isSelectionDisabled}
            onClick={handleSelectProduct}
            variant="primary"
            {...taggingData}
          >
            {ctaLabel}
          </SelectButton>
        </ProductHandles>

        <MoreInfoCTA
          id={slug}
          aria-hidden="true"
          data-testid={`product_card.${trackingId}.MORE_INFO`}
          {...getTaggingData({
            action: {
              trigger: TRIGGERS.CLICK,
              action: ACTIONS.CLICKED,
            },
            properties: {
              entity: ENTITIES.PRODUCT,
              details: {
                button_description: 'more_info',
              },
            },
          })}
          href={linkToProduct}
        >
          {moreInfoText}
        </MoreInfoCTA>
      </CTAsWrapper>
    </>
  );
};
